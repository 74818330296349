import { Component } from '@angular/core';

@Component({
  selector: 'app-thrive-splash',
  templateUrl: './thrive-splash.component.html',
  styleUrls: ['./thrive-splash.component.scss']
})
export class ThriveSplashComponent {
  constructor() {}
}
