import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-thrive-button',
  templateUrl: './thrive-button.component.html',
  styleUrls: [ './thrive-button.component.scss' ]
})
export class ThriveButtonComponent {

  @Input() disabled = false;
  @Input() color: ThemePalette;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  // Explicitly outputs the click event - this allows the button click to propagate out of the wrapper div
  @Output() click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
}
