import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-spinner',
  templateUrl: './panel-spinner.component.html',
  styleUrls: [ './panel-spinner.component.scss' ]
})
export class PanelSpinnerComponent {

  @Input() public tag: any;
  @Input() diameter: number;
  public isLoading = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public async showAsyncProgressForFunction<T>(process: () => Promise<T>): Promise<T> {
    try {
      this.isLoading = true;
      this.changeDetectorRef.detectChanges();
      return await process();
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }
}
