[
	"cy",
	"dv",
	"sw",
	"eu",
	"af",
	"am",
	"ha",
	"ku",
	"ml",
	"no",
	"ps",
	"sd",
	"so",
	"sq",
	"ta",
	"tg",
	"tt",
	"ug",
	"ur",
	"vi",
	"ar",
	"az",
	"be",
	"bg",
	"bn",
	"bs",
	"ca",
	"cs",
	"da",
	"de",
	"el",
	"en",
	"es",
	"et",
	"fa",
	"fi",
	"fr",
	"gl",
	"el",
	"he",
	"hi",
	"hr",
	"hu",
	"hy",
	"id",
	"is",
	"it",
	"ja",
	"ka",
	"kk",
	"km",
	"ko",
	"ky",
	"lt",
	"lv",
	"mk",
	"mn",
	"ms",
	"nb",
	"nl",
	"nn",
	"pl",
	"pt",
	"ro",
	"ru",
	"sk",
	"sl",
	"sr",
	"sv",
	"th",
	"tr",
	"uk",
	"uz",
	"zh"
]
