import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private snackBar: MatSnackBar) { }

  public showMessage(message): void {
    this.snackBar.open(
      message.toString(),
      '',
      { duration: 5000, verticalPosition: 'bottom' }
    );
  }
}
