import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a hyphen separated lower case string to a space separated title case string
 */
@Pipe({
  name: 'convertStringToTitleCase'
})
export class ConvertStringToTitleCasePipe implements PipeTransform {

  transform(value: string): string {
    return value.split('-')
      .map(word => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  }

}
