import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertUnixTimeToDateString'
})
export class ConvertUnixTimeToDateStringPipe implements PipeTransform {

  transform(value: number): string {
    return new Date(value).toDateString();
  }

}
