import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThriveAsyncButtonComponent } from './thrive-async-button/thrive-async-button.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ThriveButtonComponent } from './thrive-button/thrive-button.component';
import { ThriveCardComponent } from './thrive-card/thrive-card.component';
import { ThriveSplashComponent } from './thrive-splash/thrive-splash.component';
import { MaterialModule } from '../shared/modules/material/material.module';

@NgModule({
  declarations: [
    ThriveAsyncButtonComponent,
    ThriveButtonComponent,
    ThriveCardComponent,
    ThriveSplashComponent
  ],
  exports: [
    ThriveAsyncButtonComponent,
    ThriveButtonComponent,
    ThriveCardComponent,
    ThriveSplashComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule
  ]
})
export class ThriveDesignModule {}
