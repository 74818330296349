import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SignupConfiguration } from 'src/app/modules/signup/models/signup-configuration';

@Component({
  selector: 'app-region-error',
  templateUrl: './region-error.component.html',
  styleUrls: ['./region-error.component.scss']
})
export class RegionErrorComponent implements OnInit {
  config: SignupConfiguration;

  constructor(
    private activatedRoute: ActivatedRoute,
    public readonly translate: TranslateService,
  ) {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe(configuration => {
      this.config = configuration;
    });
  }
}
