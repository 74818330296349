import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invokeFunction'
})
export class InvokeFunctionPipe implements PipeTransform {

  /**
   * Allows a function to be executed within a pipe from the HTML template, without it constantly being triggered by the Angular change
   * detection.
   * @param func The function to be executed
   * @param args The arguments of the function
   */
  transform<T extends (...args) => unknown>(func: T, ...args: Parameters<T>): ReturnType<T> {
    return func(...args) as ReturnType<T>;
  }

}
