import { Pipe, PipeTransform } from '@angular/core';
import { PhoneService } from './phone.service';

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {

  constructor(private phoneService: PhoneService) {}

  transform(regionCode: string): string {
    return this.phoneService.countryNameForRegion(regionCode);
  }

}
