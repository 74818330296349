<app-thrive-splash>
  <div fxLayout="column" fxFlexFill>
    <div id="signup-header" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px"
         fxLayout.xs="column" fxFlex.xs="noshrink">
      <div *ngIf="config?.swapLogoPositions; then swappedLogosHeader else regularLogosBlock"></div>
      <ng-template #regularLogosBlock>
        <div *ngIf="config?.clientLogo" class="logo-container">
          <img [src]="config.clientLogo" alt="{{config.clientName.toLowerCase()}} {{'Logo' | translate}}"/>
        </div>
        <div *ngIf="config?.extraLogo" class="logo-container">
          <img [src]="config.extraLogo" alt="{{config.clientName.toLowerCase()}} {{'AdditionalLogo' | translate}}"/>
        </div>
        <div class="logo-container">
          <img src="./assets/images/thrive-logo-with-title.png" alt="thrive {{'Logo' | translate}}"/>
        </div>
      </ng-template>
      <ng-template #swappedLogosHeader>
        <div class="logo-container">
          <img src="./assets/images/thrive-logo-with-title.png" alt="thrive {{'Logo' | translate}}"/>
        </div>
        <div *ngIf="config.clientLogo" class="logo-container">
          <img [src]="config.clientLogo" alt="{{config.clientName.toLowerCase()}} {{'Logo' | translate}}"/>
        </div>
        <div *ngIf="config.extraLogo" class="logo-container">
          <img [src]="config.extraLogo" alt="{{config.clientName.toLowerCase()}} {{'AdditionalLogo' | translate}}"/>
        </div>
      </ng-template>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="card-container">
      <app-thrive-card>
        <mat-card-content>
          <div> {{'RegionAccessCodeError' | translate}}. </div>
          <div [innerHTML]="'SupportHelpText' | translate"> </div>
        </mat-card-content>
      </app-thrive-card>
    </div>
  </div>
</app-thrive-splash>
