<!--When mat-flat-button gets rendered, it creates extra HTML which in turn will fire an event when clicked.
    This wrapper swallows any click event, while the button's click event can still be used from a parent component
    via the click.emit()-->
<div (click)="$event.stopPropagation()">
  <button mat-flat-button
          (click)="click.emit()"
          [type]="type"
          [disabled]="disabled"
          [color]="color"
          [ngClass]="{'disabled': disabled, 'coloured': color}">
    <ng-content></ng-content>
  </button>
</div>

