import { UntypedFormGroup } from '@angular/forms';

export function mapObjectPropertiesToFormInputs(object: any, form: UntypedFormGroup): void {
  Object.keys(form.controls).forEach(key => {
    if (form.controls[key] instanceof UntypedFormGroup) {
      if (object[key]) {
        mapObjectPropertiesToFormInputs(object[key], form.controls[key] as UntypedFormGroup);
      }
    } else {
      form.get(key).setValue(object[key] == null ? null : object[key]);
    }
  });
}

// TODO: Assess whether this is needed in the future
// Clears a form and resets the validators
// Currently a bug in FormGroup reset: https://github.com/angular/material2/issues/4190
// Workaround implemented: https://stackoverflow.com/questions/48216330/angular-5-formgroup-reset-doesnt-reset-validators
export function resetForm(form: UntypedFormGroup): void {
  form.reset();
  form.clearValidators();
  for (const field of Object.keys(form.controls)) {
    form.controls[field].setErrors(null);
  }
}
