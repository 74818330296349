/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class BETAUNSTABLEService extends __BaseService {
  static readonly BetaAccessCodeAllowListAssertCodeAllowedPath = '/beta/access-code-allowlist/action/assert-code-allowed/{accessCode}';
  static readonly LazyChatRequestRequestChatLazyPath = '/beta/request-chat-lazy/beta/request-chat-lazy';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * assert that an access code is allowed to access the beta
   *
   * Used to assert if an access code is allowed to access the beta features.  Throws a NotFoundError is the given access code is not on
   * the allow list.  Throws a NotImplementedError if the allow list is not configured.
   * @param accessCode access code to check against the allow list
   */
  BetaAccessCodeAllowListAssertCodeAllowedResponse(accessCode: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/beta/access-code-allowlist/action/assert-code-allowed/${encodeURIComponent(String(accessCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * assert that an access code is allowed to access the beta
   *
   * Used to assert if an access code is allowed to access the beta features.  Throws a NotFoundError is the given access code is not on
   * the allow list.  Throws a NotImplementedError if the allow list is not configured.
   * @param accessCode access code to check against the allow list
   */
  BetaAccessCodeAllowListAssertCodeAllowed(accessCode: string): __Observable<null> {
    return this.BetaAccessCodeAllowListAssertCodeAllowedResponse(accessCode).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * To be called when a patient is sending a message in chat. Generates a chat request (pubnub only!) for the patient if they don't
   * already have one and there are no therapists currently in a chat with them.
   *
   * This endpoint will, for the authenticated patient:
   * - Check if they have an active V1 chat
   * - Check how many therapists are in a chat with them
   * - Check if there is already a chat request for them
   * - If there is no chat request, then request one in the usual way.
   * @param params The `BETAUNSTABLEService.LazyChatRequestRequestChatLazyParams` containing the following parameters:
   *
   * - `message`: The message to send to the therapist in the chat request, if sent.
   *
   * - `upgradeV1`: Whether to upgrade the patient's ongoing V1 chat to a V2 chat if there is one to upgrade
   *
   * @return chat session created, with the chatRoomId in the response body
   */
  LazyChatRequestRequestChatLazyResponse(params: BETAUNSTABLEService.LazyChatRequestRequestChatLazyParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.upgradeV1 != null) __params = __params.set('upgradeV1', params.upgradeV1.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/beta/request-chat-lazy/beta/request-chat-lazy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * To be called when a patient is sending a message in chat. Generates a chat request (pubnub only!) for the patient if they don't
   * already have one and there are no therapists currently in a chat with them.
   *
   * This endpoint will, for the authenticated patient:
   * - Check if they have an active V1 chat
   * - Check how many therapists are in a chat with them
   * - Check if there is already a chat request for them
   * - If there is no chat request, then request one in the usual way.
   * @param params The `BETAUNSTABLEService.LazyChatRequestRequestChatLazyParams` containing the following parameters:
   *
   * - `message`: The message to send to the therapist in the chat request, if sent.
   *
   * - `upgradeV1`: Whether to upgrade the patient's ongoing V1 chat to a V2 chat if there is one to upgrade
   *
   * @return chat session created, with the chatRoomId in the response body
   */
  LazyChatRequestRequestChatLazy(params: BETAUNSTABLEService.LazyChatRequestRequestChatLazyParams): __Observable<string> {
    return this.LazyChatRequestRequestChatLazyResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module BETAUNSTABLEService {

  /**
   * Parameters for LazyChatRequestRequestChatLazy
   */
  export interface LazyChatRequestRequestChatLazyParams {

    /**
     * The message to send to the therapist in the chat request, if sent.
     */
    message: string;

    /**
     * Whether to upgrade the patient's ongoing V1 chat to a V2 chat if there is one to upgrade
     */
    upgradeV1?: boolean;
  }
}

export { BETAUNSTABLEService }
