import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appInvisible]'
})
export class HideDirective implements OnInit {

  @Input('appInvisible') shouldHide: boolean;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.elementRef.nativeElement.style.visibility = this.shouldHide ? 'hidden' : 'visible';
  }
}
