import { Pipe, PipeTransform } from '@angular/core';
import { TimeAgoPipe } from 'ngx-pipes';

@Pipe({
  name: 'timeAgoExtended'
})
export class TimeAgoExtendedPipe extends TimeAgoPipe implements PipeTransform {

  constructor(private timeAgoPipe: TimeAgoPipe) {
    super();
  }

  transform(inputDate: number): string {
    const date: Date = new Date(inputDate);
    return this.timeAgoPipe.transform(date);
  }
}
