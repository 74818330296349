import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../services/helper.service';

@Pipe({
  name: 'convertDateToTime'
})
export class ConvertDateToTimePipe implements PipeTransform {

  constructor(private helperService: HelperService) {}

  transform(date: Date, is24Hours?: boolean): string {
    return this.helperService.convertDateToTime(date, is24Hours);
  }
}
