import { Pipe, PipeTransform } from '@angular/core';
import { PhoneService } from './phone.service';

@Pipe({
  name: 'diallingPrefix'
})
export class DiallingPrefixPipe implements PipeTransform {

  constructor(private phoneService: PhoneService) {}

  transform(regionCode: 'string'): string {
    return this.phoneService.diallingPrefixForRegion(regionCode);
  }

}
