import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

/**
 * Custom date adapter preserves the ordering of the DD/MM in the Material datepicker.
 * When you lose focus of a date picker, the day and month swap around because it appears that the NativeDateAdapter always favours the
 * American date formatting, making it necessary for a custom implementation of NativeDateAdapter
 */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  parse(value: string) {
    const timeArray = value.split('/');
    // If three elements, then it must be a complete date
    if (timeArray.length === 3) {
      // Return in correct format
      return new Date(+timeArray[2], +timeArray[1] - 1, +timeArray[0]);
    }
  }

  format(date: Date) {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
}
