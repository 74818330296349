import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-thrive-card',
  templateUrl: './thrive-card.component.html',
  styleUrls: [ './thrive-card.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ThriveCardComponent {

}
