import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * Abstraction to allow us to mock the environment global data in tests
 * while using a <script> tag to define the data in the runtime
 */
export abstract class InjectableEnvironmentService{
  protected constructor(private readonly http: HttpClient) {}

  abstract getEnvironment(): any;

  public getVersion() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get('/assets/version.json', httpOptions);
  }
}
