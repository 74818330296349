import { ActivatedRoute } from '@angular/router';

export function getEmailFromQueryString(activatedRoute: ActivatedRoute): string {
  let email = '';
  activatedRoute.queryParams.subscribe(params => {
    email = params.email;
  });
  // TODO: Uncomment this line when dealing with encoded URLs for live
  // return this.urlbase64decode(email);
  return email;
}
