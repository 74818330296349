<div fxLayout="column" fxLayoutAlign="space-between" id="dialog-container">
  <div fxLayout="column" fxFlex="grow">
    <div id="progress-bar">
      <mat-progress-bar *ngIf="isLoading" mode="buffer"></mat-progress-bar>
    </div>
    <div class="content">
      <h3 *ngIf="title">{{title}}</h3>
      <ng-content></ng-content>
    </div>
  </div>

  <div id="dialog-controls" fxLayout="row"
       [fxLayoutAlign]="confirmButtonText && cancelButtonText ? 'space-between' : 'center'">
    <button [fxHide]="!cancelButtonText"
            type="button"
            mat-raised-button
            [disabled]="isLoading"
            (click)="cancelClicked()">{{cancelButtonText}}
    </button>
    <button [fxHide]="!confirmButtonText"
            type="submit"
            mat-raised-button
            [color]="confirmButtonColor"
            [disabled]="!isConfirmButtonEnabled || isLoading"
            (click)="confirmClicked()">{{confirmButtonText}}
    </button>
  </div>
</div>


