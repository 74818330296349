import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-thrive-async-button',
  templateUrl: './thrive-async-button.component.html',
  styleUrls: ['./thrive-async-button.component.scss']
})
export class ThriveAsyncButtonComponent {

  @Input() action: () => Promise<void>;
  @Input() disabled = false;

  constructor() { }

  public isLoading = false;

  public async buttonClicked(): Promise<void> {
    this.isLoading = true;
    try {
      await this.action();
    } catch (err) {
      console.error(err);
    }
    this.isLoading = false;
  }

}
